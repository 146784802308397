import ViewportResized from '../helper/viewportResized';
import MobileNavigation from './mobileNavigation';
import DesktopNavigation from './desktopNavigation'

export default class Navigation {

  constructor(el) {

    this.$el = el;
    this.chooseNavigation();

  }

  // Choose which navigation to use mobile/desktop
  chooseNavigation() {
    let mm = gsap.matchMedia();

    mm.add("(max-width: 1024px)", () => {
      new MobileNavigation(this.$el);
    });

    mm.add("(min-width: 1025px)", () => {
      new DesktopNavigation(this.$el);
    });
  }
}
