export default class QuoteForm {
  constructor(c) {
    // GSAP default easing
    gsap.defaults({
      ease: "power4.out",
      duration: 0.3,
    });

    // Variables
    this.$c = c;
    this.submitBtn = c.querySelector('button[type="submit"]');
    this.spinnerSlot = this.submitBtn.querySelector(".spinner");
    let that = this;

    // Dropzone file upload plugin
    // --------------

    const $dropzoneField = this.$c.querySelector(".dropzone"),
      url = c.getAttribute("action"),
      dropzone = new Dropzone($dropzoneField, {
        dictDefaultMessage: "Dateien anhängen",
        dictRemoveFile: "Entfernen",
        url: url,
        uploadMultiple: true,
        autoProcessQueue: false,
        parallelUploads: 20,
        addRemoveLinks: true,
        init: function () {
          // console.log("Init Dropzone", url, $dropzoneField);
        },
      });

    // Fetch form
    // --------------

    c.addEventListener("submit", function (e, f) {
      e.preventDefault();
      e.stopPropagation();

      const action = c.getAttribute("action");
      const method = c.getAttribute("method");
      const formData = new FormData(c);

      // Ensure myDropzone is properly initialized
      if (!dropzone) {
        return;
      }

      // Check if there are files in Dropzone's queue
      if (dropzone.getQueuedFiles().length > 0) {
        // console.log("dropzone has some files", dropzone.getQueuedFiles());
        // Manually adding Dropzone files to the form's FormData object
        dropzone.getQueuedFiles().forEach(function (file) {
          formData.append("file[]", file);
          // console.log("file added to formData: ", file);
        });
      }

      // Add spinner animation on submit button until response is received
      that.showSpinner();

      // Handle Google recaptcha v3
      grecaptcha.ready(function () {
        grecaptcha
          .execute("6LeO_SIqAAAAALYe6kZoqI6895nW4mhCGpT0HD3m", {
            action: "submit",
          })
          .then(function (token) {
            // Add your logic to submit to your backend server here.
            console.log("let's go...");
            // Add the reCAPTCHA token to the form data
            formData.append("g-recaptcha-response", token);

            // for (const value of formData.values()) {
            //   console.log(value);
            // }

            // Send form values via ajax and handle results
            fetch(action, {
              method: method,
              body: formData,
            })
              .then((res) => {
                if (!res.ok) {
                  throw new Error(`HTTP error! status: ${res.status}`);
                }
                return res.json();
              })
              .then(function (data) {
                that.removeErrors();

                // If submission was successful, clear the Dropzone
                dropzone.removeAllFiles(true);

                if (data.alert) {
                  console.log("errors found: ", data.alert);

                  // Show custom error if the data.alert response has a "recaptcha" key
                  if (data.alert.recaptcha) {
                    // prepend a field before .bottom-bar and insert a message
                    let captchaMessage = document.createElement("span");
                    captchaMessage.innerText = "Unser System stuft Sie als Roboter ein. Bitte versuchen Sie es erneut oder melden Sie sich bitte telefonisch oder per E-Mail bei uns. Vielen Dank für Ihr Verständnis.";
                    captchaMessage.classList.add("captcha-error");
                    c.querySelector(".bottom-bar").after(captchaMessage);
                  }
                  that.setErrors(data.alert);
                } else {
                  console.log("success: ", data.success);
                  that.showSuccess(data.success);
                }
              })
              .catch((error) => {
                console.error("Fetch error: ", error);
                // Handle the fetch error, maybe by showing an error message to the user
                that.removeErrors();
                that.setErrors({
                  general: "An error occurred while submitting the form.",
                });
              });
          });
      });
    });

    // Toggle additional address fields
    this.toggleAdditionalAddress(c);
  }

  // Show success message and hide the form
  // --------------

  showSuccess(msg) {
    // console.log("show success: ", msg);

    // Animate success message
    const $success = document.querySelector(".get-quote-form__success");
    $success.classList.add("active");
    gsap.from($success, {
      opacity: 0,
      y: "+=16",
    });

    // Hide spinner
    this.hideSpinner();

    // But keep the button disabled
    this.submitBtn.disabled = true;
  }

  // Take the server side validation and add errors to the input fields
  // --------------

  setErrors(v) {
    for (const field in v) {
      // Add invalid class
      let input = this.$c.querySelector(`#${field}`);
      if (input) input.classList.add("invalid");

      // Create and append error message
      let errorMessage = document.createElement("span");
      errorMessage.innerText = v[field];
      errorMessage.classList.add("error-message");
      // console.log(input);
      if (input) {
        input.parentNode.appendChild(errorMessage);
      }
    }
  }

  // Clean up old error messages
  // --------------

  removeErrors() {
    // Remove previous/old invalid classes
    let oldErrors = this.$c.querySelectorAll(".invalid");
    // console.log(oldErrors);
    oldErrors.forEach((oe) => {
      oe.classList.remove("invalid");
    });

    // Remove previous/old error messages
    let oldErrorMessages = this.$c.querySelectorAll(".error-message");
    oldErrorMessages.forEach((em) => {
      em.remove();
    });

    this.hideSpinner();
  }

  // Show spinner in submit button
  // --------------

  showSpinner() {
    this.submitBtn.classList.add("loading");
    this.spinnerSlot.innerHTML =
      '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>';
    this.submitBtn.disabled = true;
  }

  // Hide spinner in submit button
  // --------------

  hideSpinner() {
    this.submitBtn.classList.remove("loading");
    this.spinnerSlot.innerHTML = "";
    this.submitBtn.disabled = false;
  }

  // Toggle additional address fields
  // --------------
  toggleAdditionalAddress(c) {
    const $additionalAddressCheckbox = c.querySelector("#additionalAddress");
    const $toggle = c.querySelector(".toggle");

    // $additionalAddressCheckbox.addEventListener("change", function (event) {
    //   if (this.checked) {
    //     $toggle.open = true;
    //   } else {
    //     $toggle.open = false;
    //   }
    // });

    $toggle.addEventListener("toggle", function (event) {
      if (this.open) {
        $additionalAddressCheckbox.checked = true;
      } else {
        $additionalAddressCheckbox.checked = false;
      }
    });
  }
}
