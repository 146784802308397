import ViewportResized from '../helper/viewportResized';
import {
  getRemToPx
} from '../helper/remToPx';

class HomeNews {

  constructor(el) {

    this.$news = el.querySelectorAll('.home-news__card');
    let cssGridGap = getComputedStyle(document.documentElement).getPropertyValue('--grid-gap');
    if (this.$news.length && window.innerHeight >= 900) {
      this.$news.forEach((n, i) => {

        // Pin stack the cards
        ScrollTrigger.create({
          // markers: {startColor: "blue", endColor: "black", fontSize: "14px"},
          trigger: n,
          start: () => `-=${(getRemToPx(cssGridGap) * 0.5) * (i+1)}`,
          endTrigger: el,
          end: () => {
            const cardsHeight = n.getBoundingClientRect().height + (this.$news.length) * (getRemToPx(cssGridGap) * 0.5);
            return `bottom +=${cardsHeight}`;
          },
          pin: true,
          anticipatePin: true,
          pinSpacing: false,
          snap: false,

          // Scale down previous card when new card comes up
          onEnter: () => {
            const $next = this.$news[i + 1] || null;
            if ($next) {
              gsap.to(n, {
                scrollTrigger: {
                  // markers: true,
                  trigger: $next,
                  start: () => `top ${n.getBoundingClientRect().bottom + getRemToPx(cssGridGap) * (i+1)}px`,
                  scrub: true,
                  end: "bottom bottom",
                },
              })
            }
          },
        });
      });

      // Recalc values on resize
      function recalcValues(e) {
        // Update cssGridGap
        cssGridGap = getComputedStyle(document.documentElement).getPropertyValue('--grid-gap');
        // Update ScrollTrigger
        ScrollTrigger.refresh();
      }
      new ViewportResized(recalcValues);

    }

  }

}

export default HomeNews;
