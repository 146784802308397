import drawSVG from '../vendor/DrawSVGPlugin';

export default class MobileNavigation {
  constructor(el) {

    // you only need to define the configuration settings you want to CHANGE. Omitted properties won't be affected.
    gsap.config({
      nullTargetWarn: false,
    });

    // GSAP default easing
    gsap.defaults({
      ease: "power4.out",
      duration: 0.3
    });

    // Avoid tree shaking error in production build
    const plugins = [drawSVG];
    gsap.registerPlugin(drawSVG);

    // Variables
    this.$body = document.body;
    this.$nav = el;
    this.$logo = document.querySelector('#header .logo');
    this.$backdrop = this.addBackdrop();
    this.navTL = this.initNavTimeline();

    // Mobile Switch
    this.$mobileSwitch = document.getElementById('mobileSwitch');
    this.mobileSwitchTL = this.initMobileSwitch();

    // Sub menus
    this.$menuToggles = this.$nav.querySelectorAll('.toggle');
    this.$menuBackToggle = document.querySelector('#header .submenu-back');
    this.currentSubmenuTL = null;

    // Master Timeline
    this.masterTL = gsap.timeline();
    this.masterTL.add(this.navTL, "a");
    this.masterTL.add(this.mobileSwitchTL, "a");
    this.masterTL.reverse();

    // Events
    this.handleEvents();
  }

  // Add a div at the end of the body tag with the class of "mobile-nav-backdrop"
  addBackdrop() {
    const backdrop = document.createElement('div');
    backdrop.classList.add('mobile-nav-backdrop');
    document.body.appendChild(backdrop);
    return backdrop;
  }


  // Initialize navigation Timeline
  initNavTimeline() {

    // Timeline & Variables
    let
      navTL = gsap.timeline({
        onStart: () => {
          this.$nav.classList.add('open');
        },
        onReverseComplete: () => {
          this.$nav.classList.remove('open');
        },
      });

    navTL.to(this.$backdrop, {
      opacity: 1,
      zIndex: 2,
    }, "a");
    navTL.from(this.$nav, {
      y: "-=8",
      opacity: 0,
    }, "a+=0.15");

    return navTL;
  }



  // Initialize mobile switch Timeline
  initMobileSwitch() {

    // Show mobile switch when ready
    gsap.to(this.$mobileSwitch, {
      opacity: 1,
    });

    // Timeline
    let mobileTL = gsap.timeline({
      onComplete: () => {
        this.$body.classList.add('mobile-nav-open');
      },
      onReverseComplete: () => {
        this.$body.classList.remove('mobile-nav-open');
      },
    });

    // Mobile Navigation Switch Timeline
    const
      navBtnSvg = this.$mobileSwitch.querySelector('#mobileSwitchSVG'),
      burger = navBtnSvg.querySelectorAll('#burger line'),
      close = navBtnSvg.querySelector('#close'),
      closeUp = navBtnSvg.querySelector('#close line#up'),
      closeDown = navBtnSvg.querySelector('#close line#down');

    mobileTL.set(close, {
      opacity: 1
    });
    mobileTL.staggerFromTo(burger, 0.2, {
      x: "0",
      drawSVG: "0% 100%"
    }, {
      x: "+=10",
      drawSVG: "0% 0%"
    }, 0.1);
    mobileTL.fromTo(closeDown, 0.2, {
      x: "-=10",
      y: "-=10",
      drawSVG: "100% 100%"
    }, {
      x: "0",
      y: "0",
      drawSVG: "0% 100%"
    }, "-=0.25");
    mobileTL.fromTo(closeUp, 0.2, {
      x: "-=10",
      y: "+=10",
      drawSVG: "100% 100%"
    }, {
      x: "0",
      y: "0",
      drawSVG: "0% 100%"
    }, "-=0.25");
    mobileTL.to(this.$nav, 0.2, {
      x: "0"
    }, "-=0.25");
    // mobileTL.reverse();

    return mobileTL;
  }

  // Handle events
  handleEvents() {

    // Toggle mobile switch button and navigation
    this.$mobileSwitch.addEventListener('click', () => {
      if (!this.masterTL.isActive()) {
        // Only reverse the direction if the tween is not active
        this.masterTL.reversed() ? this.open() : this.close();
      }
    });

    // Close mobile navigation on link click
    this.$nav.querySelectorAll('a').forEach((el) => {
      el.addEventListener('click', () => {
        if (this.masterTL.reversed() === false) {
          this.close();
        }
      });
    });

    // Close mobile navigation on logo click
    this.$logo.addEventListener('click', () => {
      if (this.masterTL.reversed() === false) {
        this.close();
      }
    });

    // Open Submenu
    this.$menuToggles.forEach((toggleBtn) => {
      toggleBtn.addEventListener('click', this.openSubmenu.bind(this, toggleBtn));
    });

    // Close Submenu
    this.$menuBackToggle.addEventListener('click', () => {
      if (this.currentSubmenuTL) {
        this.currentSubmenuTL.reverse();
      }
    });
  }

  openSubmenu(toggleBtn) {

    let menu = toggleBtn.nextElementSibling;
    let submenuTL = gsap.timeline({
      onStart: () => {
        toggleBtn.setAttribute("aria-expanded", true);
        menu.setAttribute("aria-hidden", false);
        menu.hidden = false;
      },
      onReverseComplete: () => {
        toggleBtn.setAttribute("aria-expanded", false);
        menu.setAttribute("aria-hidden", true);
        menu.hidden = true;
      }
    });

    submenuTL.to(this.$logo, {
      autoAlpha: 0,
      x: "-=8"
    }, "a");
    submenuTL.to(this.$menuBackToggle, {
      autoAlpha: 1,
      x: "0"
    }, "a+=0.15");
    submenuTL.to(this.$nav, {
      x: "-=100%",
    }, "a");

    this.currentSubmenuTL = submenuTL;

  }

  // Open mobile navigation
  open() {
    this.masterTL.play();
  }

  // Close mobile navigation
  close() {
    this.masterTL.reverse();
    if (this.currentSubmenuTL) {
      this.currentSubmenuTL.reverse();
    }
  }

}
