class ViewportResized {
  constructor(callback) {
    this.resizeTimer = null;
    this.handleViewportResize = this.handleViewportResize.bind(this);
    this.callback = callback;
    this.init();
  }

  handleViewportResize() {
    clearTimeout(this.resizeTimer);

    this.resizeTimer = setTimeout(() => {
      const viewportWidth = window.innerWidth;

      if (this.callback && typeof this.callback === 'function') {
        this.callback(viewportWidth);
      }
    }, 300);
  }

  init() {
    this.handleViewportResize();

    window.addEventListener('resize', this.handleViewportResize);
  }
}

export default ViewportResized;
