class HomeSustainability {

  constructor(el) {

    this.$numbers = el.querySelectorAll('.home-sustainability__number');

    ScrollTrigger.create({
      // markers: true,
      trigger: el,
      start: "top 50%",
      once: true,
      onEnter: () => {
        gsap.from(this.$numbers, {
          textContent: 0,
          duration: 1.5,
          ease: "linear",
          snap: {
            textContent: 1
          },
          stagger: {
            each: 0.5,
            onUpdate: function () {
              this.targets()[0].innerHTML = this.targets()[0].textContent;
            },
          }
        });
      }
    })

  }

}

export default HomeSustainability;
