import ViewportResized from '../helper/viewportResized';
import {
  getRemToPx
} from '../helper/remToPx';

class HomeIntro {

  constructor(el, toTopButton) {
    this.$section = el;
    this.toTopButton = toTopButton;
    this.$container = this.$section.querySelector('.home-intro__container');
    this.$references = this.$section.querySelector('.home-intro__references');
    this.$scrollDown = this.$section.querySelector('.scroll-down');
    this.cssGridGap = getComputedStyle(document.documentElement).getPropertyValue('--grid-gap');

    this.initScrollTrigger();
    this.recalcCSSValues();
    this.initLoopGallery();

    // Update toTopButton values
    const sectionScrolledHeight = this.$section.getBoundingClientRect().top + window.scrollY;
    if (this.toTopButton) this.toTopButton.initScrollTrigger(sectionScrolledHeight);
  }

  // Automatic loop gallery for last card
  initLoopGallery() {
    let imageElements = Array.from(this.$container.querySelectorAll('.loop picture'));
    let currentIndex = 0;

    function switchImage() {
      imageElements[currentIndex].classList.remove('active');
      currentIndex = (currentIndex + 1) % imageElements.length;
      imageElements[currentIndex].classList.add('active');
    }

    setInterval(switchImage, 2500);
  }

  // Init ScrollTrigger to achieve horizontal scrolling
  initScrollTrigger() {

    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(Draggable);

    let maxWidth = this.$references.scrollWidth - this.$references.offsetWidth;

    let scrollTween = gsap.to(this.$references, {
      x: () => `-${this.$references.scrollWidth - this.$references.offsetWidth}`,
      ease: "power1.inOut",
    });

    let horizontalScroll = ScrollTrigger.create({
      // markers: true,
      animation: scrollTween,
      invalidateOnRefresh: true,
      trigger: this.$references,
      start: () => `-=${getRemToPx(this.cssGridGap)}`,
      end: `+=${this.$references.scrollWidth - this.$references.offsetWidth}`,
      pin: true,
      pinSpacing: true,
      anticipatePin: true,
      scrub: true,
      onEnter: () => {
        this.$scrollDown.classList.add('active');
      },
      onLeave: () => {
        this.$scrollDown.classList.remove('active');
      },
      onLeaveBack: () => {
        this.$scrollDown.classList.remove('active');
      }
    });


    let dragRatio = (maxWidth / (maxWidth - window.innerWidth));

    // Ugly fix
    dragRatio = 1.5;

    let drag = Draggable.create(".drag-proxy", {
      trigger: this.$references,
      type: "x",
      inertia: true,
      allowContextMenu: true,
      onPress() {
        this.startScroll = horizontalScroll.scroll();
      },
      onDrag() {
        horizontalScroll.scroll(this.startScroll - (this.x - this.startX) * dragRatio);
      },
      onThrowUpdate() {
        horizontalScroll.scroll(this.startScroll - (this.x - this.startX) * dragRatio);
      }
    })[0];
  }

  // Recalc CSS Values when viewport changes
  recalcCSSValues() {
    // Recalc values on resize
    function recalc(e) {
      // Update cssGridGap
      this.cssGridGap = getComputedStyle(document.documentElement).getPropertyValue('--grid-gap');
      // Update ScrollTrigger
      ScrollTrigger.refresh();
    }
    new ViewportResized(recalc);
  }

}

export default HomeIntro;
