import ViewportResized from '../helper/viewportResized';
import {
  getRemToPx
} from '../helper/remToPx';


class HorizontalGallery {

  constructor(el, toTopButton) {
    this.$section = el;
    this.toTopButton = toTopButton;
    this.$references = this.$section.querySelector('.horizontal-gallery__wrapper');
    this.$scrollDown = this.$section.querySelector('.scroll-down');
    this.cssGridGap = getComputedStyle(document.documentElement).getPropertyValue('--grid-gap');

    this.initHorizontalScroll();

    // Update toTopButton values
    const sectionScrolledHeight = this.$section.getBoundingClientRect().top + window.scrollY;
    if (this.toTopButton) this.toTopButton.initScrollTrigger(sectionScrolledHeight);

    // Recalc values on resize
    new ViewportResized(this.recalcValues);

    // Init Fancybox
    Fancybox.bind("[data-fancybox]");

  }


  initHorizontalScroll() {

    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(Draggable);

    let maxWidth = this.$references.scrollWidth - this.$references.offsetWidth;

    let scrollTween = gsap.to(this.$references, {
      x: () => `-${maxWidth}`,
      ease: "power1.inOut",
    });

    let horizontalScroll = ScrollTrigger.create({
      // markers: true,
      animation: scrollTween,
      invalidateOnRefresh: true,
      trigger: this.$references,
      start: () => `-=${getRemToPx(this.cssGridGap)}`,
      end: `+=${maxWidth}`,
      pin: true,
      pinSpacing: true,
      anticipatePin: true,
      scrub: true,
      onEnter: () => {
        this.$scrollDown.classList.add('active');
      },
      onLeave: () => {
        this.$scrollDown.classList.remove('active');
      },
      onLeaveBack: () => {
        this.$scrollDown.classList.remove('active');
      }
    });


    let dragRatio = (maxWidth / (maxWidth - window.innerWidth));

    // Ugly fix
    dragRatio = 1.5;

    let drag = Draggable.create(".drag-proxy", {
      trigger: this.$references,
      type: "x",
      inertia: true,
      allowContextMenu: true,
      onPress() {
        this.startScroll = horizontalScroll.scroll();
      },
      onDrag() {
        horizontalScroll.scroll(this.startScroll - (this.x - this.startX) * dragRatio);
      },
      onThrowUpdate() {
        horizontalScroll.scroll(this.startScroll - (this.x - this.startX) * dragRatio);
      }
    })[0];
  }

  recalcValues() {

    // Update cssGridGap
    this.cssGridGap = getComputedStyle(document.documentElement).getPropertyValue('--grid-gap');

    // Update ScrollTrigger
    ScrollTrigger.refresh();

  }

}

export default HorizontalGallery;
