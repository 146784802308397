// Base
import Cookies from './components/cookies'
import Navigation from './components/navigation'
import AnchorLinks from './components/anchorLinks';

// Homepage
import HomeIntro from './components/homeIntro'
import Truncate from './components/truncate'
import HomeNews from './components/homeNews'
import HomeSustainability from './components/homeSustainability'
import HomeFormImage from './components/homeFormImage'

// Everything else
import QuoteForm from './components/quoteForm'
import Accordion from './components/accordion'
import ReferenceCard from './components/reference-card'
import HorizontalGallery from './components/horizontal-gallery'
import Process from './components/process'
import HeroIntro from './components/heroIntro'
import ToTopButton from './components/toTopButton'

// Main Navigation
const $navigation = document.getElementById('navigation');
if ($navigation) new Navigation($navigation);

// Init Cookies Banner
new Cookies();

// Init Anchor Links
new AnchorLinks();

// To Top Button
const toTopButton = new ToTopButton();

// Init parallax images
const parallaxImages = document.querySelectorAll('.ukiyo');
parallaxImages.forEach((el) => {
  new Ukiyo(el, {
    scale: 1.1, // 1~2 is recommended
    speed: 1.2, // 1~2 is recommended
    willChange: true, // This may not be valid in all cases
  });
});

// Init Fancybox
Fancybox.bind("[data-fancybox]");


// Intro slider
const $homeIntro = document.getElementById('home-intro');
if ($homeIntro) {
  new HomeIntro($homeIntro, toTopButton);
}

// Home News Scrolltrigger
const $homeNews = document.getElementById('home-news');
if ($homeNews) {
  new HomeNews($homeNews);
}

// Home Sustainability
const $homeSus = document.getElementById('home-sustainability');
if ($homeSus) {
  new HomeSustainability($homeSus);
}

// Home Form Image
const $homeFormImage = document.getElementById('home-form-image');
if ($homeFormImage) {
  new HomeFormImage($homeFormImage);
}

// Testimonial truncate
document.querySelectorAll(".truncate").forEach((el) => {
  new Truncate(el);
});

// Accordion
document.querySelectorAll('.accordion').forEach((el) => {
  new Accordion(el);
});

// Contact form
const $qf = document.getElementById('quoteForm');
if ($qf) {
  new QuoteForm($qf);
}

// Reference card
document.querySelectorAll('.references-card__slider').forEach((el) => {
  new ReferenceCard(el);
});

// Reference gallery
let $horizontalGallery = document.getElementById('horizontal-gallery');
if ($horizontalGallery) {
  new HorizontalGallery($horizontalGallery, toTopButton);
}

// Process
const $process = document.getElementById('process');
if ($process) {
  new Process($process);
}

// Hero Intro Section
const $introSection = document.querySelector('.hero-intro-section');
if ($introSection) {
  new HeroIntro($introSection);
}


