export default class AnchorLinks {
  constructor() {

    // Animate anchor links
    const $anchorLinks = document.querySelectorAll('a[href^="#"]');

    $anchorLinks.forEach(link => {
      link.addEventListener('click', function (e) {
        e.preventDefault();
        const target = this.getAttribute('href');
        const $target = document.querySelector(target);
        if ($target) {
          const offset = $target.getBoundingClientRect().top;
          const targetPosition = window.scrollY + offset;
          window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
          });
        }
      });
    });
  }

}
