export default class Cookies {

  constructor() {
    const $body = document.body;
    const c = document.querySelectorAll('#open-cookie-modal');

    // Listen for a change in cookie settings -> reload page
    $body.addEventListener('cookies:saved', function (event) {
      location.reload();
    })

    // Call cookie consent modal on button click
    c.forEach(b => {
      b.addEventListener('click', function () {
        openCookieBanner();
      });
    });

    // Open cookie banners with custom buttons as well
    let openCookiesButtons = document.querySelectorAll('.open-cookie-settings');
    openCookiesButtons.forEach(b => {
      b.addEventListener('click', function () {
        openCookieBanner();
      });
    });

    // Actually open the banner
    function openCookieBanner() {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('cookies:update', true, false);
      $body.dispatchEvent(event);
    }

  }
}
