export default class DesktopNavigation {

  constructor(el) {

    this.$menus = el.querySelectorAll('.menu');
    this.$menus.forEach((menu) => {
      new DesktopMenu(menu);
    });

  }

}


export class DesktopMenu {
  constructor(el) {

    this.$parent = el.parentNode;
    this.$toggle = this.$parent.querySelector('.toggle');
    this.$menu = this.$parent.querySelector('.menu');
    this.opened = false;

    this.setupEventListeners();
  }


  setupEventListeners() {
    this.$toggle.addEventListener('click', this.toggleMenu.bind(this));
    document.addEventListener('keydown', this.closeMenuOnEsc.bind(this));
    document.addEventListener('click', this.closeMenuOnClickOutside.bind(this));
    const menuLinks = this.$menu.querySelectorAll('a:not([data-fancybox])');
    menuLinks.forEach((link) => {
      link.addEventListener('click', this.closeMenu.bind(this));
    });
  }

  toggleMenu() {
    if (this.opened) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  openMenu() {
    const previouslyOpenMenu = document.querySelector('.menu--open');
    if (previouslyOpenMenu) {
      previouslyOpenMenu.classList.remove('menu--open');
      previouslyOpenMenu.querySelector('.toggle').setAttribute("aria-expanded", false);
      previouslyOpenMenu.querySelector('.menu').setAttribute("aria-hidden", true);
      previouslyOpenMenu.querySelector('.menu').hidden = true;
    }

    this.$menu.hidden = false;
    this.$parent.classList.add("menu--open");

    this.$toggle.setAttribute("aria-expanded", true);
    this.$menu.setAttribute("aria-hidden", false);

    this.opened = true;
  }

  closeMenu() {
    this.$menu.hidden = true;
    this.$parent.classList.remove("menu--open");
    this.$toggle.setAttribute("aria-expanded", false);
    this.$menu.setAttribute("aria-hidden", true);

    this.opened = false;
  }

  closeMenuOnEsc(e) {
    if (e.key === 'Escape') {
      this.closeMenu();
    }
  }

  closeMenuOnClickOutside(e) {
    if (!this.$parent.contains(e.target) && this.opened) {
      this.closeMenu();
    }
  }
}
