export default class HomeFormImage {

  constructor(el) {

    this.$el = el;
    this.$container = el.querySelector('.parallax-container');
    this.$image = el.querySelector('img');

    if (window.innerWidth >= 1200) {
      this.initStickyImage();
      this.initParallax();
    }

  }

  initStickyImage() {

    // Pin stack the cards
    ScrollTrigger.create({
      // markers: true,
      trigger: this.$container,
      start: "top top",
      endTrigger: this.$el,
      end: "bottom bottom",
      pin: true,
      anticipatePin: true,
      pinSpacing: false,
      snap: false,
    });
  }


  initParallax() {

    new Ukiyo(this.$image, {
      speed: 1.2, // 1~2 is recommended
      willChange: true, // This may not be valid in all cases
    });
  }

}
