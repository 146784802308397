export default class ReferenceCard {

  constructor(el) {
    this.el = el;
    this.$swiper = el.querySelector('.swiper');

    // Make sure clicking the slider buttons doesn't trigger a page transition
    this.$buttons = this.el.querySelectorAll('.swiper-button');
    this.$buttons.forEach(button => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
      });
    });

    // Create the slider
    new Swiper(this.$swiper, {
      loop: true,
      preventClicksPropagation: true,
      pagination: {
        el: this.el.querySelector('.swiper-pagination'),
      },
      navigation: {
        nextEl: this.el.querySelector('.swiper-button__next'),
        prevEl: this.el.querySelector('.swiper-button__prev'),
      },
    });
  }

}
