import ViewportResized from '../helper/viewportResized';
import {
  getRemToPx
} from '../helper/remToPx';

export default class Process {

  constructor(el) {

    this.$el = el;
    this.$steps = el.querySelectorAll('.process-card');
    this.cssGridGap = getComputedStyle(document.documentElement).getPropertyValue('--grid-gap');

    if (this.$steps.length && window.innerHeight > 1024) this.initCardStacking();
    this.recalcCSSValues();

  }

  // Stack cards with gsap scrolltrigger
  initCardStacking() {

    this.$steps.forEach((n, i) => {

      // Pin stack the cards
      ScrollTrigger.create({
        // markers: true,
        trigger: n,
        start: () => `-=${(getRemToPx(this.cssGridGap) * 0.5) * (i+1)}`,
        endTrigger: this.$el,
        end: () => {
          const cardsHeight = n.getBoundingClientRect().height + (this.$steps.length) * (getRemToPx(this.cssGridGap) * 0.5);
          return `bottom +=${cardsHeight}`;
        },
        pin: true,
        anticipatePin: true,
        pinSpacing: false,
        snap: false,

        // Scale down previous card when new card comes up
        onEnter: () => {
          const $next = this.$steps[i + 1] || null;
          // const scales = [0.75, 0.8, 0.85, 0.9, 0.95, 1];
          if ($next) {
            gsap.to(n, {
              // scale: () => scales[i],
              scrollTrigger: {
                // markers: true,
                trigger: $next,
                start: () => `top ${n.getBoundingClientRect().bottom + getRemToPx(this.cssGridGap) * (i+1)}px`,
                scrub: true,
                end: "bottom bottom",
              },
            })
          }
        },
      });
    });
  }

  // Recalc CSS Values when viewport changes
  recalcCSSValues() {
    // Recalc values on resize
    function recalc(e) {
      // Update cssGridGap
      this.cssGridGap = getComputedStyle(document.documentElement).getPropertyValue('--grid-gap');
      // Update ScrollTrigger
      ScrollTrigger.refresh();
    }
    new ViewportResized(recalc);
  }
}
