export default class HeroIntro {

  constructor(el) {

    this.$el = el;
    this.$heading = el.querySelector('.hero-intro-section__heading');
    this.$media = el.querySelector('.hero-intro-section__media');
    this.cssGridGap = getComputedStyle(document.documentElement).getPropertyValue('--grid-gap');

    this.stickyHeading();

  }

  stickyHeading() {
    let mm = gsap.matchMedia();

    mm.add("(min-width: 1024px)", () => {

      // check if the media is taller than the viewport
      if (this.$media.offsetHeight > window.innerHeight) {

        this.$el.classList.add('sticky');

        ScrollTrigger.create({
          // markers: true,
          pin: this.$heading,
          pinSpacing: false,
          trigger: document.body,
          start: "top top",
          endTrigger: this.$media,
          end: "bottom bottom"
        })

      }

    });
  }

}
